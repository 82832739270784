import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import LinkItem from './link-item';
import { CustomBreadcrumbsProps } from './types';

// Define styles
const useStyles = makeStyles((theme) => ({
  capitalize: {
    textTransform: 'capitalize',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '30ch',
  },
}));

export default function CustomBreadcrumbs({
  links,
  action,
  heading,
  moreLink,
  activeLast,
  sx,
  showBackArrow,
  onBackClick,
  fixed = false,
  ...other
}: CustomBreadcrumbsProps) {
  const classes = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const lastLink = links[links.length - 1].name;

  useEffect(() => {
    if (fixed) {
      const handleScroll = () => {
        if (window.scrollY > 10) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
    return () => {};
  }, [fixed]);

  return (
    <Box
      sx={{
        position: fixed ? 'sticky' : 'relative',
        top: fixed ? 62 : 'auto',
        p: 3,
        zIndex: 90,
        backgroundColor: scrolled && fixed ? 'white' : 'transparent',
        boxShadow: scrolled && fixed ? '0px 4px 12px rgba(0, 0, 0, 0.1)' : 'none',
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
        minHeight: 60,
        height: 120,
        ...sx
      }}
    >
      <Box sx={{ ...sx }}>
        <Stack direction="row" alignItems="center">
          {showBackArrow && (
            <IconButton onClick={onBackClick}>
              <Typography variant="h6">&lt;</Typography>
            </IconButton>
          )}
          <Box sx={{ flexGrow: 1 }}>
            {/* BREADCRUMBS */}
            {!!links.length && (
              <Breadcrumbs aria-label="breadcrumb" separator={<Separator />} {...other}>
                {links.map((link) => (
                  <LinkItem
                    key={link.name || ''}
                    link={link}
                    activeLast={activeLast}
                    disabled={link.name === lastLink}
                  />
                ))}
              </Breadcrumbs>
            )}

            {/* HEADING */}
            {heading && (
              <Typography marginTop={1} variant="h3" gutterBottom className={classes.capitalize}>
                {heading}
              </Typography>
            )}
          </Box>

          {action && <Box sx={{ flexShrink: 0, pt: 2.5}}> {action} </Box>}
        </Stack>

        {/* MORE LINK */}
        {!!moreLink && (
          <Box>
            {moreLink.map((href) => (
              <Link key={href} href={href} variant="body2" target="_blank" rel="noopener">
                {href}
              </Link>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}

// Separator component
function Separator() {
  return (
    <Box
      component="span"
      sx={{
        width: 4,
        height: 4,
        borderRadius: '50%',
        bgcolor: 'text.disabled',
        mx: 0,
      }}
    />
  );
}
