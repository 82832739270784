import { makeStyles } from '@material-ui/styles';
import { Controller, useFormContext } from 'react-hook-form';
import { FC, useRef, useState, useEffect, ChangeEvent } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { Box, Fade, Modal, Button, IconButton, Typography } from '@mui/material';

import Iconify from 'src/components/iconify/iconify';
import SvgColor from 'src/components/svg-color/svg-color';

interface FileUploadParam {
  defaultValue: any;
  onChange: (file: any) => void;
  editable: boolean;
  name: string;
  error?: any;
}

interface ImageModalProps {
  open: boolean;
  handleClose: () => void;
  image: any;
}

const ImageViewer: FC<ImageModalProps> = ({ open, handleClose, image }) => {
  const classes = styles();

  return (
    <Modal className={classes.modal} open={open} onClose={handleClose} closeAfterTransition>
      <div className={classes.imagePreview}>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
        <TransformWrapper initialScale={1} minScale={1} maxScale={4}>
          {({ zoomIn, zoomOut }) => (
            <>
              <div className={classes.zoomControls}>
                <Button variant="contained" onClick={() => zoomIn()} className={classes.zoomButton}>
                  Zoom in +
                </Button>
                <Button
                  variant="contained"
                  onClick={() => zoomOut()}
                  className={classes.zoomButton}
                >
                  Zoom out -
                </Button>
              </div>
              <TransformComponent>
                <Fade in={open} timeout={500} className={classes.img}>
                  <img src={image} alt="" className={classes.imageStyle} />
                </Fade>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </div>
    </Modal>
  );
};

const FileUploader: FC<FileUploadParam> = ({ defaultValue, onChange, name, editable }) => {
  const { control } = useFormContext();
  const classes = styles();
  const [currentImage, setCurrentImage] = useState(defaultValue);
  const [open, setOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (defaultValue instanceof File) {
      const fileUrl = URL.createObjectURL(defaultValue);
      setCurrentImage(fileUrl);
    } else {
      setCurrentImage(defaultValue);
    }
  }, [defaultValue]);

  const handleButtonClick = () => {
    if (!editable) return;
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      const fileUrl = URL.createObjectURL(selectedFile);
      setCurrentImage(fileUrl);
      onChange(selectedFile);
      event.target.value = '';
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImageClick = () => {
    setOpen(true);
  };

  const RenderEdit = () => (
    <IconButton onClick={handleButtonClick} className={classes.iconContainer}>
      <Iconify icon="solar:restart-bold" width={12} height={12} />
    </IconButton>
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {currentImage ? (
              <Box>
                {editable ? <RenderEdit /> : null}
                <Box onClick={handleImageClick}>
                  <img src={currentImage} className={classes.imageContainer} alt="" />
                </Box>
              </Box>
            ) : (
              <Button
                variant="outlined"
                name={name}
                onClick={handleButtonClick}
                className={[classes.imageContainer, error && classes.error].join(' ')}
              >
                <SvgColor src="/assets/icons/app/gallery-icon.svg" />
              </Button>
            )}
            {error && (
              <Typography
                variant="caption"
                color="error"
                style={{ marginTop: '8px', textAlign: 'center' }}
              >
                Image is required
              </Typography>
            )}
          </Box>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <ImageViewer open={open} handleClose={handleClose} image={currentImage} />
        </>
      )}
    />
  );
};

const styles = makeStyles({
  imageContainer: { borderRadius: 10, height: 50, width: 55, objectFit: 'cover' },
  error: {
    borderColor: 'red',
  },
  iconContainer: {
    backgroundColor: 'white',
    position: 'absolute',
    right: -4,
    top: -5,
    borderColor: 'red',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 4,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imagePreview: {
    position: 'relative',
    maxHeight: '75vh',
    maxWidth: '50vw',
    backgroundColor: 'transparent',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  imageStyle: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
  },

  img: {
    outline: 'none',
    objectFit: 'cover', // Ensure image fits inside modal
    width: '100%',
    height: '100%',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    backgroundColor: 'red',
    color: 'white',
    zIndex: 1000,
    '&:hover': {
      backgroundColor: 'darkred',
    },
  },
  zoomControls: {
    position: 'fixed',
    bottom: 30,
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    gap: '10px',
    zIndex: 1000,
  },
  zoomButton: {
    width: '110px',
    height: '40px',
    fontSize: '1rem',
    color: '#000',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#d3d3d3',
    },
  },
});

export default FileUploader;
