import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, Divider, Typography, IconButton } from '@mui/material';

interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  onSubmit?: () => void;
  buttons: React.ReactNode;
  children: React.ReactNode;
  width?: string | number;
  maxWidth?: number;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  onClose,
  title,
  onSubmit,
  buttons,
  children,
  width,
  maxWidth,
}) => {
  const style = {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width || '90%',
    maxWidth: maxWidth || 550,
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    position: 'relative',
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title">
      <Box sx={style}>
        <Box sx={boxStyle1}>
          <Typography id="modal-title" variant="h6">
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ my: 1.5 }} />

        <form onSubmit={onSubmit} noValidate>
          <Box sx={boxStyle3}>{children}</Box>
          {buttons && (
            <Box sx={footerContainerStyle}>
              <Box sx={footerStyle}>{buttons}</Box>
            </Box>
          )}
        </form>
      </Box>
    </Modal>
  );
};

const boxStyle1 = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  px: 3,
  pt: 3,
};

const footerContainerStyle = {
  bottom: 0,
  width: '100%',
  backgroundColor: 'white',
  boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.1)',
  zIndex: 10,
};

const footerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px',
};

const boxStyle3 = { maxHeight: '70vh', overflowY: 'auto', paddingBottom: '10px', p: 2.7 }; // Ensure space for sticky footer

export default CustomModal;
