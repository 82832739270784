/* eslint-disable */

import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useCallback } from 'react';

import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { formatDate } from 'src/utils/date-utils';

import { salesStatus, transactionType } from 'src/datas';
import { appStatus, operationsStatus } from 'src/datas/status-styles';
import { RootState, AppDispatch } from 'src/store/store';
import FilterComponent from 'src/layouts/common/filter-search';
import { getAllTransactions } from 'src/store/slices/transaction-slice';

import TableSkeleton from 'src/components/table/table-skeleton';
import TableHeadCustom from 'src/components/table/table-head-custom';
import { TableWrapperProps } from 'src/interfaces';
import { getReleaseApprovals } from 'src/apis/sales';
import {
  fetchNegotiationApprovals,
  fetchReleaseApprovals,
  resetRecords,
} from 'src/store/slices/sales-slices';
import { SalesStatus } from 'src/datas/sales-status';
import formatINR from 'src/utils/format-inr';

// TableWrapper component
const TableWrapper: React.FC<TableWrapperProps> = ({ children }) => (
  <TableContainer sx={{ overflowX: 'auto' }}>{children}</TableContainer>
);
// Table labels
const tableLabels = [
  { id: 'customer_id', label: 'Customer ID', align: 'center' },
  { id: 'customer_name', label: 'Customer', align: 'center' },
  { id: 'service_charge', label: 'Service Charge', align: 'center' },
  { id: 'final_amount', label: 'Final Amount', align: 'center' },
  { id: 'transaction_type', label: 'Transaction Type', align: 'center' },
  { id: 'date', label: 'Transaction Date', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: '', label: 'Action' },
];

// Table rows
const RenderRows = ({ row }: any) => {
  const router = useRouter();
  const { loading } = useSelector((store: RootState) => store.sales);
  const classes = useStyles();

  const navigateToTransaction = (estimationId: string, transaction_id: string) => {
    router.push(`estimations/${estimationId}/transactions/${transaction_id}`);
  };

  return loading ? (
    <TableSkeleton />
  ) : (
    <TableRow>
      <TableCell align="center">{row.customer?.id}</TableCell>
      <TableCell align="center">
        <p className={classes.name}>
          {row.customer?.first_name} {row.customer?.last_name}
        </p>
      </TableCell>
      <TableCell align="center">{row.estimation?.service_charge}%</TableCell>
      <TableCell align="center">₹{formatINR(row.estimation?.final_amount)}</TableCell>
      <TableCell align="center">{transactionType[row?.transaction_type]}</TableCell>
      <TableCell align="center">{formatDate(row?.created_at)}</TableCell>

      <TableCell align="center">
        <Card sx={SalesStatus[row.estimation?.negotiation_approval]?.style}>
          {SalesStatus[row.estimation?.negotiation_approval]?.label}
        </Card>
      </TableCell>

      <TableCell>
        <Button onClick={() => navigateToTransaction(row?.estimation?.id, row?.id)} variant="outlined">
          View
        </Button>
      </TableCell>
    </TableRow>
  );
};

// Main render function
function NegotiationApprovalTable({
  status,
}: {
  status: 'PENDING' | 'ALL' | 'APPROVED' | 'REJECTED' | '';
}) {
  const dispatch = useDispatch<AppDispatch>();
  const { records, totalRecords, loading } = useSelector((store: RootState) => store.sales);

  // State management
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [selectedTransactionType, setSelectedTransactionType] = useState<string>('');
  const [searchInitiated, setSearchInitiated] = useState(false); // Track if search has been initiated

  const fetchTransactions = async () => {
    try {
      const resp = await dispatch(
        fetchNegotiationApprovals({
          status: status,
          pageSize,
          page: page + 1,
          searchText: searchQuery,
          startDate,
          endDate,
          transactionType: selectedTransactionType,
        })
      );
      return resp;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  useEffect(() => {
    if (searchInitiated) {
      fetchTransactions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, searchQuery, startDate, endDate, selectedTransactionType, status]);

  const handleDateChange = (startDateVal: string, endDateVal: string) => {
    setStartDate(startDateVal);
    setEndDate(endDateVal);
    setSearchInitiated(true); // Set search initiated to true
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setSearchInitiated(true); // Set search initiated to true
  };

  const handleTransactionTypeChange = (type: string) => {
    setSelectedTransactionType(type);
    setSearchInitiated(true); // Set search initiated to true
  };

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPageSize(parseInt(event.target.value, 10));
      setPage(0);
    },
    [setPage, setPageSize]
  );

  return (
    <div>
      <FilterComponent
        onDateChange={handleDateChange}
        onSearch={handleSearch}
        onTransactionTypeChange={handleTransactionTypeChange}
        searchResultCount={searchInitiated ? totalRecords : 0} // Pass totalRecords when search initiated
      />
      <Card sx={{ borderRadius: 0 }}>
        <TableWrapper>
          <Table>
            <TableHeadCustom headLabel={tableLabels} />
            <TableBody>
              {records?.length > 0 ? (
                records.map((row: any) => <RenderRows key={row.id} row={row} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={tableLabels.length} align="center">
                    No records found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableWrapper>
      </Card>
    </div>
  );
}

// custom stylings
const useStyles = makeStyles({
  name: {
    fontWeight: 600,
  },
});

export default NegotiationApprovalTable;
