/* eslint-disable */
// @ts-nocheck
import { ReleaseAgreement, CustomerPayments, ReleaseFormValues,   FinalPaymentApprovalSales,
} from 'src/interfaces';

import API from '..';
import { release, payments, transaction } from '../entpoints';

export const createPledgeEstimation = async (
  estimation: ReleaseFormValues,
  transactionId: string
) => {
  try {
    const formData = new FormData();
    formData.append('transaction_id', transactionId);

    const formKeys = Object.keys(estimation) as Array<keyof ReleaseFormValues>;
    formKeys.forEach((key) => {
      const value: any = estimation[key];
      if (value) {
        formData.append(key, value);
      }
    });

    const resp = await API.post(release.create, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// update pledge estimation
export const updatePledgeEstimation = async (
  estimation: ReleaseFormValues,
  transactionId: string
) => {
  try {
    const formData = new FormData();
    formData.append('transaction_id', transactionId);

    const formKeys = Object.keys(estimation) as Array<keyof ReleaseFormValues>;
    formKeys.forEach((key) => {
      const value: any = estimation[key];
      formData.append(key, value);
    });

    const resp = await API.patch(`${release.update}/${estimation.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// fetch pledge estimation by details
export const fetchPledgeEstimationById = async (id: string) => {
  try {
    const resp = await API.get(`${release.get}/${id}`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// Proceed to agreement
export const proceedToAgreement = async (transactionId: string) => {
  try {
    const payload = {
      status: 'RELEASE_AGREEMENT_PENDING',
    };
    const resp = await API.patch(`${transaction.index}/${transactionId}`, payload);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// request release agreement
export const fetchTakeoverAgreement = async (transactionId: string) => {
  try {
    const resp = await API.get(
      `${release.getTakeoverAgreement}/${transactionId}/takeover_agreement`
    );
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const submitAgreement = async (data: ReleaseAgreement, releaseId: string) => {
  try {
    const form = new FormData();

    form.append('release[bank_name]', data.bank_name || '');
    form.append('release[account_holder_name]', data.account_holder_name || '');
    form.append('release[account_number]', data.account_number || '');
    form.append('release[ifsc_code]', data.ifsc_code || '');
    if (data.balance_amount_screenshot) {
      form.append('release[balance_amount_screenshot]', data.balance_amount_screenshot);
    }
    form.append('release[payee]', data.payee_type);
    form.append('agreement[signed]', data.signed as any);

    const resp = await API.patch(`${release.submitAgreement}/${releaseId}/sign_agreement`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const submitFinalAmount = async (data: FinalPaymentApprovalSales, releaseId: string) => {
  try {
    const resp = await API.patch(`${release.submitFinalAmount}/${releaseId}/final_amount`, data);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const confirmRelease = async (releaseId: string) => {
  try {
    const resp = await API.patch(`${release.releasePickup}/${releaseId}/confirm_pickup`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const makepayments = async (payment: CustomerPayments, transaction_id: string) => {
  try {
    const formData = new FormData();
    formData.append('transaction_id', transaction_id);

    const formKeys = Object.keys(payment) as Array<keyof CustomerPayments>;
    formKeys.forEach((key) => {
      const value: any = payment[key];
      if (value) {
        formData.append(key, value);
      }
    });

    const resp = await API.post(`${release.makePayment}/${transaction_id}/payment`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};

export const paymentReceived = async (paymentId: string) => {
  try {
    const resp = await API.post(`${release.receivedPayment}/${paymentId}/confirm_payment`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const paymentById = async (paymentId: string) => {
  try {
    const resp = await API.get(`${payments.getPayments}/${paymentId}`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getReleasePaymentById = async (transaction_id: string) => {
  try {
    const resp = await API.get(
      `${release.makePayment}/${transaction_id}/confirm_payment`
    );
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message)
  }
};
